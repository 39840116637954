<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-avatar color="white">
      <img src="@/assets/mmo-square-logo.png" />
    </v-list-item-avatar>

    <v-list-item-content class="pl-2 primary--text">
      {{ $t('app.title') }}
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// Utilities
import { get } from 'vuex-pathify'

export default {
  name: 'DefaultDrawerHeader',

  computed: { version: get('app/version') }
}
</script>
